@import "../../../fonts/icomoon/variables.scss";
@import "../../mixins/bs_breakpoints.scss";
@import "../../mixins/adjustable-font-size";

.parallax {
  background-color: #000;
  width: 100%;
  height: 100% !important;
  position: relative;
  overflow: hidden;
  transition: none;

  @include media-breakpoint-up(md) {
    transition: opacity .3s ease 1s;
  }

  * {
    user-select: none;
  }

  &.passive {
    opacity: 0;
    transition: none;

    @include media-breakpoint-up(md) {
      transition: opacity .7s ease;
    }
  }


  #scene {
    height: 100vh;
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  &__bg {
    background-image: url(/static/images/parallax-bg_orange.webp);
    width: 100%;
    background-size: cover;
    background-position: 50%;
    height: 100%;
    opacity: .75;

  }

  &__title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    padding-right: 15px;
    padding-left: 15px;
    @include interpolate(font-size, 320px, 767px, 26px, 38px);
    @include interpolate(width, 768px, 1920px, 500px, 800px);
    margin-top: 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
      font-weight: 300;
      @include interpolate(font-size, 768px, 1920px, 32px, 60px);
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.5;
    }

    @media (max-width: 767px) and (orientation: landscape) {
      @include interpolate(font-size, 320px, 767px, 20px, 36px);
    }
  }

  &__contact-buttons {
    display: block;
    margin-left: calc(97% - 65px);
    margin-top: calc(90vh - 180px);
    position: absolute;
    z-index: 3010;

    @include media-breakpoint-down(sm) {
      display: none !important;
    }

    &_mobile {
      display: none;

      @include media-breakpoint-down(sm) {
        display: flex;
        margin-left: -1.5vw;
        margin-right: -1.5vw;
        margin-top: 5vh;
        justify-content: space-around;
        width: 100%;
      }

      @media (max-width: 767px) and (orientation: landscape) {
        justify-content: space-between;
      }
    }

  }

  &__contact-button {
    @include interpolate(width, 768px, 1920px, 50px, 65px);
    @include interpolate(height, 768px, 1920px, 50px, 65px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    box-shadow: 0 0 11px 2px rgba(255, 255, 255, 0.39);
    margin-bottom: 50px;
    cursor: pointer;
    pointer-events: auto;
    border: none;
    background-color: transparent;

    @media (max-width: 767px) and (orientation: landscape) {
      transform: translateY(-170%);
      width: 70px !important;
    }

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      span {
        color: $accent;
      }
    }

    svg {
      @include interpolate(font-size, 768px, 1920px, 21px, 27px);
      transition: color .3s ease;

    }

    .icon-mail {
      color: #fff;
      font-size: 40px;
      transition: background-image .3s ease;
    }

    .icon-phone {
      color: #fff;
      font-size: 25px;
    }


    @include media-breakpoint-down(sm) {
      margin-top: 3vw;
      width: calc(38vw - 3vw);
      border-radius: 5px;
      margin-left: 4.5vw;
      margin-right: 4.5vw;
      position: relative;
      z-index: 5;

      svg {
        font-size: 4vw;
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 35%;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    svg {
      font-size: 5vw;
    }
  }

  &__text {
    width: 60%;
    @include interpolate(font-size, 768px, 1920px, 15px, 16px);
    text-align: center;
    opacity: .8;
    margin-top: 20px;

    @media (max-width: 1500px) {
      width: 60%;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__button {
    display: block;
    border: none;
    border-radius: 50%;
    @include interpolate(width, 768px, 1920px, 65px, 90px);
    @include interpolate(height, 768px, 1920px, 65px, 90px);
    background-image: repeating-radial-gradient(ellipse farthest-corner at center center, #f0a636 0, #e27926 100%);
    padding-right: 10px;
    padding-left: 10px;
    z-index: 3010;
    position: relative;
    pointer-events: auto;

    &:focus {
      & + div.parallax__button-text {
        color: $accent;
      }
    }

    @include media-breakpoint-down(sm) {
      @include interpolate(width, 320px, 767px, 65px, 140px);
      @include interpolate(height, 320px, 767px, 65px, 140px);
    }

    @media (max-width: 767px) and (orientation: landscape) {
      @include interpolate(width, 320px, 767px, 40px, 95px);
      @include interpolate(height, 320px, 767px, 40px, 95px);
    }

    &:after {
      content: '';
      opacity: 0;
      top: 0;
      left: 0;
      margin-left: -6%;
      margin-top: -6%;
      border: 3px solid #fff;
      @include interpolate(width, 768px, 1920px, 75px, 100px);
      @include interpolate(height, 768px, 1920px, 75px, 100px);
      z-index: 0;
      position: absolute;
      border-radius: 50%;
      animation: 1.1s linear infinite pulse;

      @include media-breakpoint-down(sm) {
        @include interpolate(width, 320px, 767px, 75px, 150px);
        @include interpolate(height, 320px, 767px, 75px, 150px);
      }

      @media (max-width: 767px) and (orientation: landscape) {
        @include interpolate(width, 320px, 767px, 50px, 105px);
        @include interpolate(height, 320px, 767px, 50px, 105px);
      }

    }


    &-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) and (orientation: landscape) {
        margin-top: 0;
      }
    }

    &-text {
      margin: 0 0 0 30px;
      @include interpolate(font-size, 768px, 1920px, 24px, 30px);
      letter-spacing: .5px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        @include interpolate(font-size, 320px, 767px, 18px, 30px);
        margin-left: 10px;
      }

      @media (max-width: 767px) and (orientation: landscape) {
        @include interpolate(font-size, 320px, 767px, 18px, 24px);
        margin-left: 15px;
      }
    }

    .icon-Play {
      color: #fff;
      left: 50%;
      top: 50%;
      margin-right: -5px;
      font-size: 40px;
    }

    p {
      display: block;
      white-space: normal;
      margin-bottom: 0;
      margin-top: 5px;
      line-height: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__logo {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
    @include interpolate(margin-top, 768px, 1920px, 120px, 345px);
    left: 0;


    @media (max-width: 1024px) and (orientation: portrait) {
      @include interpolate(margin-top, 768px, 1024px, 320px, 600px);
    }

    @include media-breakpoint-down(sm) {
      margin-top: 40vh;
    }


    @media (max-width: 767px) and (orientation: landscape) {
      margin-top: 50vh;
    }

    &_img {
      @include interpolate(width, 768px, 1920px, 350px, 700px);
      @include interpolate(margin-left, 768px, 1920px, 114px, 525px);
      @include interpolate(margin-top, 768px, 1920px, -70px, -100px);
      will-change: transform;
      z-index: 5;

      @media (max-width: 1024px) and (orientation: portrait) {
        @include interpolate(width, 768px, 1024px, 558px, 814px);
        margin-left: 0;
      }

      @include media-breakpoint-down(sm) {
        @include interpolate(width, 320px, 767px, 300px, 500px);
        @include interpolate(margin-left, 320px, 767px, 20px, 143px);
        @include interpolate(margin-top, 320px, 767px, 0px, -70px);
      }

      @media (max-width: 767px) and (orientation: landscape) {
        @include interpolate(width, 320px, 767px, 200px, 400px);
        @include interpolate(margin-left, 320px, 767px, 50px, 195px);
      }
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    display: flex;

    .image {
      width: 1920px;
      min-width: 100vw;
      position: absolute;
      height: 150%;
      background-size: 100%;
      overflow: visible;

      @include media-breakpoint-down(lg) {
        width: 120%;
      }

      &_1 {
        top: -100px;
        left: 0;
      }

      &_2 {
        top: 0;
        left: 100%;
      }
    }

    @for $i from 1 through 4 {
      &_#{$i} {
        .image {
          background-image: url(/static/images/parallax_#{$i}.svg);
        }
      }
    }

    &_1 {
      will-change: margin-left;
      animation: Gradient1 40s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }

    }

    &_2 {
      will-change: margin-left, transform;
      animation: Gradient2 100s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }
    }

    &_3 {
      will-change: margin-left, transform;
      animation: Gradient3 200s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }
    }

    &_4 {
      will-change: margin-left, transform;
      animation: Gradient4 320s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }

    }

    &_moving {
      position: absolute;
      top: 0;
    }

  }

}

@for $i from 1 through 4 {
  @keyframes Gradient#{$i} {
    0% {
      margin-left: 0;
      opacity: 0;
    }

    5% {
      opacity: 1;
    }


    10% {
      margin-left: -10%;
      opacity: 1;
    }

    20% {
      margin-left: -20%;
      opacity: 1;
    }

    30% {
      margin-left: -30%;
      opacity: 1;
    }

    40% {
      margin-left: -40%;
      opacity: 1;
    }

    50% {
      margin-left: -50%;
      opacity: 1;
    }

    60% {
      margin-left: -60%;
      opacity: 1;
    }

    70% {
      margin-left: -70%;
      opacity: 1;

    }

    80% {
      margin-left: -80%;
      opacity: 1;
    }

    90% {
      margin-left: -90%;
      opacity: 1;

    }

    95% {
      margin-left: -90%;
      opacity: 1;

    }

    100% {
      margin-left: -100%;
      opacity: 0;
    }
  }
}

.parallax__video {
  position: absolute;
  margin-top: 25px;
  padding-bottom: 45.25%;
  padding-top: 25px;
  height: 0;
  width: 85%;
  z-index: 4;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 100vw;
    height: calc(100vw * .5625);

    @supports (-webkit-overflow-scrolling: touch) {
      margin-left: 7%;
    }


    @media (orientation: landscape) {
      height: calc(100vw * .4825);
      margin-left: 0;
    }
  }


  &-container {
    width: calc(100vw - 210px);
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    z-index: 15;
    transition: opacity .7s ease .7s;

    @include media-breakpoint-down(md) {
      width: 100vw;
      position: absolute;
      left: 0;
      top: 0;
    }


    .video_interactive {
      transform: translateY(-200%);
      opacity: 0;
      transition: transform 0s ease 1s, opacity 1s ease;
    }

    &.active {
      opacity: 1;
      max-width: calc(100vw - 210px);
      max-height: 100vh;

      @include media-breakpoint-down(sm) {
        max-width: 100vw;
        width: 100vw;
      }

      .video_interactive {
        transform: translateY(0);
        width: 100%;
        opacity: 1;
        height: 100%;
        transition: transform 1s ease .5s, opacity 2s ease .5s;

        @include media-breakpoint-down(sm) {
          width: 100vw;
          min-width: 100vw;
        }

      }


    }

    &.active.first {

      .video_interactive {
        transition: transform 1.5s ease, opacity 2s ease;
      }
    }
  }
}

#mainVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallax-video_close {
  position: absolute;
  top: 40px;
  z-index: 15;
  display: block;
  width: 30px;
  height: 30px;
  left: 50%;
  font-size: 30px;
  transform: translate(-50%, -100px);
  background-size: cover;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0s ease 1s, transform .5s ease .5s;


  @include media-breakpoint-down(sm) {
    transform: none;
    transition: none;
  }

  &.active {
    opacity: 1;
    transform: translateX(-50%);
    transition: opacity 1s ease, transform .5s ease .5s;

    @include media-breakpoint-down(sm) {
      transition: none;
    }

  }

}

@keyframes pulse {
  0% {
    transform: scale(.9);
    opacity: .2
  }
  25% {
    opacity: .2
  }
  50% {
    transform: scale(1.2);
    opacity: .09;
    border-width: 2px
  }
  100% {
    transform: scale(2);
    opacity: .06;
    border-width: 1px
  }
}

.interactive-content {
  width: 85%;
  top: 50%;
  left: 50%;
  margin-top: 25px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 50;
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    width: 100vw;
    margin-top: 0;
    height: calc(100vw * .5625);
  }

  &:after {
    display: block;
    z-index: -1;
    content: '';
    padding-bottom: 56%;
  }
}
